export const addToSearchParams = (param: [string, string]) => {
  const url = new URL(window.location.href);
  const { searchParams } = url;
  searchParams.set(param[0], param[1]);
  url.search = searchParams.toString();
  window.history.pushState(null, "", url);
};

export const getSearchParam = (name: string) => {
  const url = new URL(window.location.href);
  const { searchParams } = url;
  const param = searchParams.get(name);
  return param;
};
