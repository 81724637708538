import { Alert, Spinner } from "react-bootstrap";
import useTask from "../../../shared/hooks/useTask";

import { FC, useEffect, useState } from "react";
import useBindKey from "../../../shared/hooks/useBindKey";
import { Task } from "../../../shared/types";
import styles from "./MultiClassification.module.css";
import MultiClassificationUI from "../../../shared/ui/MultiClassificationUI";
import { useHistory, useLocation } from "react-router";

type Props = {
  task: Task;
  onBatchComplete?: () => void;
};

// используется чтобы знать, когда нажатие на кнопку "Предыдущий" должна возвращать на страницу заданий
let nextBtnClicked = 0;

const MultiClassificationTask: FC<Props> = ({ task, onBatchComplete }) => {
  const {
    skipBatch,
    doneBatch,
    batchImages,
    setBatchImages,
    loading,
    error,
    taskCompleted,
    getTaskBatchId,
    getBatch,
  } = useTask(task.id);

  const [selected, setSelected] = useState<string[]>([]);
  const { addAction } = useBindKey(true);
  const { goBack } = useHistory();
  const location = useLocation();

  const updateImageMarkup = (cls: string) => {
    setBatchImages((prev) => {
      if (prev === null) return null;
      const copy = [...prev];
      const img = copy[0];
      const markup = img.markup as { classes: string[] } | null;
      let classes = markup ? markup.classes : [];
      if (classes.includes(cls)) {
        classes = classes.filter((c) => c !== cls);
      } else {
        classes.push(cls);
      }
      const updated = copy.map((img) => ({
        ...img,
        markup: classes.length ? { classes } : null,
      }));
      return updated;
    });
  };

  const updateSelected = (cls: string) => {
    setSelected((prev) => {
      let classes = [...prev];
      if (classes.includes(cls)) {
        classes = classes.filter((c) => c !== cls);
      } else {
        classes.push(cls);
      }
      return classes;
    });
  };

  const handleClassClick = (cls: string) => {
    updateImageMarkup(cls);
    updateSelected(cls);
  };

  const handleNextClick = async () => {
    nextBtnClicked += 1;
    const successful = await doneBatch();
    if (successful && onBatchComplete) {
      onBatchComplete();
    }
  };

  const handlePrevClick = () => {
    nextBtnClicked -= 1;
    if (nextBtnClicked < 0) {
      window.location.replace("/tasks");
    } else {
      goBack();
    }
  };

  useEffect(() => {
    addAction("Enter", () => {
      if (!selected.length) return;
      handleNextClick();
    });
    addAction(" ", skipBatch);
    addAction("Backspace", handlePrevClick);
  }, [selected]);

  useEffect(() => {
    if (!batchImages) return;
    const img = batchImages[0];
    const { markup } = img;
    setSelected(markup ? markup.classes : []);
  }, [batchImages]);

  useEffect(() => {
    task.settings.classes.forEach(({ markup_label }, i) => {
      addAction((i + 1).toString(), () => handleClassClick(markup_label));
    });
  }, [task]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const { searchParams } = url;
    const batchId = searchParams.get("batch_id");
    if (!batchId) {
      getTaskBatchId();
    } else {
      getBatch(batchId);
    }
  }, [location]);

  if (taskCompleted)
    return (
      <Alert variant="warning">
        <Alert.Heading>
          <Alert.Heading>Нет новых изображений</Alert.Heading>
        </Alert.Heading>
      </Alert>
    );

  if (error)
    return (
      <Alert variant="danger">
        <Alert.Heading>{error}</Alert.Heading>
      </Alert>
    );
  if (!batchImages || loading)
    return (
      <div className={styles["spinner-container"]}>
        <Spinner animation="border" />
        <b>Загружаем батч</b>
      </div>
    );
  return (
    <MultiClassificationUI
      images={batchImages}
      classes={task.settings.classes.map(({ markup_label }) => markup_label)}
      selectedClasses={selected}
      handleClassClick={handleClassClick}
      handleNextClick={handleNextClick}
      handleSkipClick={skipBatch}
      handlePrevClick={handlePrevClick}
    />
  );
};

export default MultiClassificationTask;
