import { FC } from "react";
import { Button as BootstrapBtn, ButtonProps } from "react-bootstrap";
import styles from "./Button.module.css";

type Props = ButtonProps & { hint?: string | number };

const Button: FC<Props> = ({ hint, children, ...props }) => {
  return (
    <div className={styles["container"]}>
      <div className={styles["hint"]}>{hint}</div>
      <BootstrapBtn {...props}>{children}</BootstrapBtn>
    </div>
  );
};

export default Button;
