import axios from "axios";
import { useEffect, useState } from "react";
import { API_URL } from "../../api";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const useBatchCount = () => {
  const [batchesCount, setBatchesCount] = useState({ today: 0, total: 0 });
  //@ts-ignore
  const { taskId } = useParams();
  // FIXME: использует старый фронт
  const currentUser = useSelector(
    (state: any) => state.userReducer.currentUser
  );

  const getUserSpentTaskTime = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const { data } = await axios.get(
        `${API_URL}/tasks/${taskId}/status/${currentUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // нужно получить батчи задания и подсчитать сделанные
      const { done_batches, today_batches } = data.data;
      setBatchesCount({ total: done_batches, today: today_batches });
    } catch (e) {
      console.error(e);
    }
  };

  const increaseBatchCount = () => {
    setBatchesCount((prev) => {
      const copy = { ...prev };
      copy["today"] += 1;
      copy["total"] += 1;
      return copy;
    });
  };

  useEffect(() => {
    getUserSpentTaskTime();
  }, []);

  return { increaseBatchCount, batchesCount };
};

export default useBatchCount;
