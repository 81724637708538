import { FC } from "react";
import "../../shared/styles/task.css";

import { API_URL } from "../../api";
import { BatchImage } from "../types";
import Button from "./Button";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

type Props = {
  images: BatchImage[];
  classes: string[];
  selectedClasses: string[];
  handleClassClick: (cls: string) => void;
  handleNextClick?: () => void;
  handleSkipClick?: () => void;
  handlePrevClick?: () => void;
  disabledButtons?: {
    skip?: boolean;
    classes?: boolean;
    next?: boolean;
  };
};

const MultiClassificationUI: FC<Props> = ({
  images,
  classes,
  selectedClasses,
  handleNextClick,
  handleSkipClick,
  handleClassClick,
  handlePrevClick,
  disabledButtons,
}) => {
  return (
    <div className="task-container">
      {images.map(({ id }) => (
        <TransformWrapper key={id} panning={{ disabled: true }}>
          <TransformComponent>
            <img
              src={`${API_URL}/images/${id}/image`}
              alt="Batch"
              className="batch-image"
            />
          </TransformComponent>
        </TransformWrapper>
      ))}
      <div className="buttons-container">
        {handlePrevClick && (
          <Button
            variant="outline-info"
            onClick={handlePrevClick}
            hint="Backspace"
          >
            Предыдущий
          </Button>
        )}
        {handleSkipClick && (
          <Button variant="outline-info" onClick={handleSkipClick} hint="Space">
            Пропустить
          </Button>
        )}
        {classes.map((cls, i) => (
          <Button
            key={cls + i}
            hint={i + 1}
            variant={selectedClasses.includes(cls) ? "info" : "outline-info"}
            onClick={() => handleClassClick(cls)}
            disabled={disabledButtons?.classes}
          >
            {cls}
          </Button>
        ))}
        {handleNextClick && (
          <Button
            variant="outline-info"
            onClick={handleNextClick}
            disabled={!selectedClasses.length}
            hint="Enter"
          >
            Следующий
          </Button>
        )}
      </div>
    </div>
  );
};

export default MultiClassificationUI;
