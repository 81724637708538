import { FC } from "react";

import { Task } from "../../../shared/types";
import styles from "./TaskCore.module.css";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import useBatchCount from "../../../shared/hooks/useBatchCount";
import MultiClassificationTask from "../MultiClassification/MultiClassificationTask";

type Props = {
  task: Task;
};
const NewTaskCore: FC<Props> = ({ task }) => {
  const { batchesCount, increaseBatchCount } = useBatchCount();

  return (
    <div className={styles["container"]}>
      <div className={styles["navigation"]}>
        <div>
          <b>Сделано картинок</b>
          <br />
          <span>Cегодня: {batchesCount.today}</span>
          <br />
          <span>За все время: {batchesCount.total}</span>
        </div>
        <Button
          onClick={() => window.location.replace("/tasks")}
          className={styles["home-btn"]}
          variant="outline-primary"
        >
          <FontAwesomeIcon icon={faHome} size="lg" />
        </Button>
      </div>
      <div className={styles["content"]}>
        {(() => {
          if (task.markup_type === "multi_classification") {
            return (
              <MultiClassificationTask
                task={task}
                onBatchComplete={increaseBatchCount}
              />
            );
          }
          return <h1>Данный тип задачи не поддерживается</h1>;
        })()}
      </div>
    </div>
  );
};

export default NewTaskCore;
