import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBatchImages,
  updateAdminMarkup,
} from "../../../../../actions/adminPanel";
import styles from "./style.module.css";
import TaskFactory from "../../../../Tasks";
import { adminPanelSelector } from "../../../selectors";
import { markupQuerySelector } from "../SideBar/selectors";
import DeleteMarkupButton from "./DeleteMarkupButton";
import { taskDataSelector } from "./selectors";
import markupApi from "../../../../../markupApi";
import MarkupViewer from "../../../../../fsd/widgets/MarkupViewer";
import { loadPage } from "../../../../../actions/adminPanel";

const NEW_MARKUP_TYPES = ["multi_classification"];

const Viewer = (props) => {
  const { batchId } = useSelector(markupQuerySelector);
  const { images, batches, markupQuery } = useSelector(adminPanelSelector);
  const taskData = useSelector(taskDataSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (images === null && batches !== null) {
      dispatch(getBatchImages(batchId));
    }
  }, [images, batches, batchId, dispatch]);

  if (taskData === null) {
    return null;
  }
  const { markup_type: taskType, settings } = taskData;
  const batch = batches.filter(({ id }) => id === batchId)[0];

  const [Batch, Buttons] = NEW_MARKUP_TYPES.includes(taskType)
    ? []
    : TaskFactory.createTask(taskType);
  const handleClassPatch = async (e) => {
    const imageId = images[0].id;
    const markup = {
      class: e.target.value,
    };
    const res = await markupApi.updateImage(imageId, markup);
    if (res.status === 200) {
      dispatch(updateAdminMarkup({ imageId, markup }));
    }
  };

  const handleTextPatch = async (text) => {
    const imageId = images[0].id;
    const markup = {
      text,
    };
    const res = await markupApi.updateImage(imageId, markup);
    if (res.status === 200) {
      dispatch(updateAdminMarkup({ imageId, markup }));
    }
  };

  const handleNewTypeBatchDelete = () => {
    const { batchId, page } = markupQuery;
    const batchIndex = batches.findIndex((b) => b.id === batchId);
    dispatch(loadPage(page, batchIndex));
  };

  const patchFunction = (() => {
    switch (taskType) {
      case "classification":
        return handleClassPatch;
      case "text":
        return handleTextPatch;
      default:
        return undefined;
    }
  })();

  const childProps = {
    ...props,
    batchImages: images,
    disabled: patchFunction === undefined,
    handleHistoryBackButtonClick: () => {},
    handleSave: patchFunction,
  };

  // set visibility for loaded batch
  let cls = styles["batch-container"];
  if (images !== null) {
    cls = styles["loaded-batch-container"];
  }

  return NEW_MARKUP_TYPES.includes(taskType) ? (
    (() => {
      if (!batch || !taskData || !images) return null;
      return (
        <MarkupViewer
          images={images}
          batch={batch}
          task={taskData}
          onDelete={handleNewTypeBatchDelete}
        />
      );
    })()
  ) : (
    <div>
      <span>{taskData.user}</span>
      <div className="text-right">
        <DeleteMarkupButton />
      </div>
      <div className="text-center">
        <div className={cls}>
          {images !== null ? (
            <div>
              <Batch {...childProps} />
            </div>
          ) : null}
        </div>
        <Buttons {...childProps} settings={settings} />
      </div>
    </div>
  );
};

export default Viewer;
