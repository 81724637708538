import { useEffect, useState } from "react";

const useBindKey = (dbg?: boolean) => {
  const [keyActionDict, setKeyActionList] = useState<{
    [key: string]: (e: KeyboardEvent) => void;
  }>({});

  const addAction = (
    key: string,
    action: (e: KeyboardEvent | undefined) => void
  ) => {
    setKeyActionList((prev) => {
      const copy = { ...prev };
      copy[key] = action;
      return copy;
    });
  };

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (keyActionDict[e.key] !== undefined) {
        keyActionDict[e.key](e);
        dbg && console.info(`${e.key} action fired`);
      } else {
        dbg && console.info(`Action for ${e.key} is not defined`);
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [keyActionDict]);

  return { addAction };
};

export default useBindKey;
