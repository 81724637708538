import { Batch, BatchImage } from "../../shared/types/batch";
import { customAxios } from "../config";
import { ApiResponse, PaginatedApiResponse } from "../types";

export const getMarkupBatch = (taskId: number) =>
  customAxios.get<any, ApiResponse<Batch>>(`/tasks/${taskId}/batch_to_markup`);

type ImageWithBatch = BatchImage & {
  batch: Batch;
};

export const getBatchImages = (batchId: number | string) =>
  customAxios.get<any, PaginatedApiResponse<ImageWithBatch[]>>("images", {
    params: { per_page: 1000, batch_id: batchId },
  });

export const updateBatchImage = async (params: { id: string; markup: any }) => {
  const { id, markup } = params;
  return customAxios.patch(`/images/${id}`, {
    markup,
  });
};

export const updateBatch = async (params: {
  id: number;
  state: "done" | "skipped" | "not_assigned";
}) => {
  const { id, state } = params;
  return customAxios.patch(`/batches/${id}`, {
    state,
  });
};

export const getBatchById = (id: number | string) => {
  return customAxios.get<any, ApiResponse<Batch>>(`/batches/${id}`);
};
