import { FC, useEffect, useState } from "react";
import { BatchImage, Task } from "../../shared/types";
import MultiClassificationUI from "../../shared/ui/MultiClassificationUI";
import { updateBatchImage } from "../../api";
import { Alert } from "react-bootstrap";
import useBindKey from "../../shared/hooks/useBindKey";

type Props = { images: BatchImage[]; task: Task };

let lockFetch = false;
const MultiClassificationViewer: FC<Props> = (props) => {
  const { images, task } = props;
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { addAction } = useBindKey(true);

  const updateSelected = async (cls: string) => {
    if (lockFetch) return;
    setError(null);
    lockFetch = true;
    const newClasses = toggleClassSelection(selected, cls);
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 500);
    try {
      for (const img of images) {
        await updateBatchImage({ ...img, markup: { classes: newClasses } });
      }
      clearTimeout(timeoutId);
    } catch (e: any) {
      console.log(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
      lockFetch = false;
    }
    setSelected((prev) => toggleClassSelection(prev, cls));
  };

  useEffect(() => {
    task.settings.classes.forEach(({ markup_label }, i) => {
      addAction((i + 1).toString(), () => updateSelected(markup_label));
    });
  }, [task]);

  useEffect(() => {
    const img = images[0];
    const { markup } = img;
    setSelected(markup ? markup.classes : []);
  }, [images]);

  if (error) {
    return (
      <Alert variant="danger">
        <Alert.Heading>{error}</Alert.Heading>
      </Alert>
    );
  }

  return (
    <div>
      <MultiClassificationUI
        images={images}
        classes={task.settings.classes.map(({ markup_label }) => markup_label)}
        selectedClasses={selected}
        handleClassClick={updateSelected}
        disabledButtons={{ classes: loading }}
      />
      {loading && <span>Подождите, идет сохранение</span>}
    </div>
  );
};

const toggleClassSelection = (classes: string[], cls: string) => {
  let newClasses = [...classes];
  if (newClasses.includes(cls)) {
    newClasses = newClasses.filter((c) => c !== cls);
  } else {
    newClasses.push(cls);
  }
  return newClasses;
};

export default MultiClassificationViewer;
