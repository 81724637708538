import { useEffect, useState } from "react";
import NewTaskCore from "../../widgets/Tasks/TaskCore";
import { useParams } from "react-router";
import { getTaskById } from "../../api";
import TaskCore from "../../../components/Tasks/TaskCore";
import { Task } from "../../shared/types";
import { Alert, Spinner } from "react-bootstrap";
import styles from "./TaskSection.module.css";

const NEW_TASK_TYPES = ["multi_classification"];

export default function TaskSection() {
  const { taskId } = useParams() as any;
  const [task, setTask] = useState<Task | null>(null);
  const [error, setError] = useState<string | null>(null);

  const checkTaskType = async () => {
    setError(null);
    try {
      const { data: taskRes } = await getTaskById(taskId);
      setTask(taskRes.data);
    } catch (e: any) {
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    }
  };

  useEffect(() => {
    checkTaskType();
  }, [taskId]);

  if (error)
    return (
      <Alert variant="danger">
        <Alert.Heading>{error}</Alert.Heading>
      </Alert>
    );

  if (!task)
    return (
      <div className={styles["spinner-container"]}>
        <Spinner animation="border" />
        <b>Загружаем задачу</b>
      </div>
    );
  if (NEW_TASK_TYPES.includes(task.markup_type))
    return <NewTaskCore task={task} />;
  return <TaskCore />;
}
