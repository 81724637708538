import { useEffect, useState } from "react";
import { Batch, BatchImage } from "../types";
import {
  getMarkupBatch,
  getBatchImages,
  updateBatchImage,
  updateBatch,
  addToSearchParams,
  getBatchById,
} from "../../api";

const useTask = (id: number) => {
  const [currentBatch, setCurrentBatch] = useState<Batch | null>(null);
  const [batchImages, setBatchImages] = useState<BatchImage[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const preRequest = () => {
    setLoading(true);
    setError(null);
  };

  const getTaskBatchId = async () => {
    preRequest();
    try {
      const { data: batchRes } = await getMarkupBatch(id);
      const markupBatch = batchRes.data;
      addToSearchParams(["batch_id", markupBatch.id.toString()]);
      await getBatch(markupBatch.id.toString());
    } catch (e: any) {
      if (e.response?.data?.error_code === "batch.BatchNotFound") {
        return setTaskCompleted(true);
      }
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    }
  };

  const getBatch = async (batchId: string) => {
    if (currentBatch?.id.toString() === batchId) return;
    preRequest();
    try {
      const { data: batchRes } = await getBatchById(batchId);
      const markupBatch = batchRes.data;
      setCurrentBatch(markupBatch);
      const { data: imagesRes } = await getBatchImages(markupBatch.id);
      const batchImages = imagesRes.data;
      const images = batchImages.map((bImg) => {
        const { batch, ...img } = bImg;
        return img;
      });
      setBatchImages(images);
    } catch (e: any) {
      if (e.response?.data?.error_code === "batch.BatchNotFound") {
        return setTaskCompleted(true);
      }
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  const nextBatch = async (state: "done" | "skipped") => {
    preRequest();
    try {
      if (!currentBatch) {
        console.log(`images: ${batchImages}, batch: ${currentBatch}`);
        throw new Error("Current batch is null");
      }
      for (const img of batchImages || []) {
        await updateBatchImage({
          ...img,
          markup: state === "skipped" ? null : img.markup,
        });
      }
      await updateBatch({ id: currentBatch.id, state });
      await getTaskBatchId();
      return true;
    } catch (e: any) {
      console.error(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  const skipBatch = () => nextBatch("skipped");
  const doneBatch = () => nextBatch("done");

  return {
    batchImages,
    skipBatch,
    doneBatch,
    setBatchImages,
    loading,
    error,
    taskCompleted,
    getTaskBatchId,
    getBatch,
  };
};

export default useTask;
