import { FC, useState } from "react";
import { Batch, BatchImage, Task } from "../../shared/types";
import MultiClassificationViewer from "./MultiClassificationViewer";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Spinner } from "react-bootstrap";
import styles from "./MarkupViewer.module.css";
import { updateBatch, updateBatchImage } from "../../api";

type Props = {
  images: BatchImage[];
  batch: Batch;
  task: Task;
  onDelete: () => void;
};

const MarkupViewer: FC<Props> = (props) => {
  const { batch, task, images, onDelete } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const deleteMarkup = async () => {
    setLoading(true);
    try {
      for (const img of images) {
        await updateBatchImage({ id: img.id, markup: null });
      }
      await updateBatch({ id: batch.id, state: "not_assigned" });
      onDelete();
    } catch (e: any) {
      console.log(e);
      setError(`Что-то пошло не так!: ${e.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    return (
      <Alert variant="danger">
        <Alert.Heading>{error}</Alert.Heading>
      </Alert>
    );
  if (loading)
    return (
      <div className={styles["spinner-container"]}>
        <Spinner animation="border" />
      </div>
    );

  return (
    <div>
      <div className={styles["navigation"]}>
        <span>{batch.user.email}</span>
        <Button variant="danger" onClick={deleteMarkup}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
      {(() => {
        if (task.markup_type === "multi_classification") {
          return <MultiClassificationViewer task={task} images={images} />;
        }
        return <h1>Неизвестный тип задания</h1>;
      })()}
    </div>
  );
};

export default MarkupViewer;
